import React, { useContext, useRef } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { NextLink, Picture } from '@/atoms';
import { DesktopMenu, MobileMenu, Toolbar } from '@/molecules/primarymenu';

import { FacadeSearchBoxDesktop } from '@/components/algolia';
import { CheckoutProvider, InitPropsProvider } from '@/components/providers';
import { useHeaderSticky, useIntersectionObserver } from '@/lib/hooks';
import { routes } from '@/lib/routes';
import { isCartRoute, isMyAccountRoute } from '@/lib/utils';
import ToastCenter from '../toast-center';
import CintaDesktop from '/public/assets/images/Bigcrafters-cinta-impulsado-eg-desktop.png';
import CintaMobile from '/public/assets/images/Bigcrafters-cinta-impulsado-eg-mobile.png';

import type { TypeImageWordpress } from '@/components/types';
import type { ToolbarProps } from '@/molecules/primarymenu/toolbar';

interface Header {
  customClaim?: {
    desktopImage: TypeImageWordpress;
    mobileImage: TypeImageWordpress;
  };
  showLoginHeader?: ToolbarProps['showLoginHeader'];
}

export const Header = ({ customClaim, showLoginHeader = true }: Header) => {
  const router = useRouter();
  const withOutSticky =
    isCartRoute(router.pathname) || isMyAccountRoute(router.pathname);

  const {
    options: { logo, logoMobile },
  } = useContext(InitPropsProvider);

  const headerRef = useRef<HTMLElement>(null);
  const cintaRef = useRef<HTMLDivElement>(null);

  const entry = useIntersectionObserver(headerRef, {
    root: null,
    rootMargin: '-0.1px',
    threshold: 0,
  });

  useHeaderSticky(headerRef, cintaRef, 30, 600, withOutSticky);

  return (
    <header
      ref={headerRef}
      style={{ top: 0 }}
      className={`${withOutSticky ? '' : 'sticky'} z-40 transition-all`}
    >
      <div className="relative top-0 flex flex-col gap-2 border-b border-b-primary-100 bg-bg-primary px-4 py-1.5 lg:py-3">
        <div className="flex flex-col lg:u-wrapper md:gap-0.5">
          <div className="relative flex flex-row items-center justify-between max-lg:h-11 lg:items-end lg:gap-5">
            <div className="flex flex-row items-center gap-6 lg:hidden">
              <MobileMenu />
              {logoMobile && (
                <NextLink href={routes.index}>
                  <a aria-label="Ir a la home" className="relative h-11 w-36">
                    <Image
                      src={logoMobile.src}
                      alt={logoMobile?.alt ?? 'Logo Header Mobile'}
                      fill
                      sizes="145px"
                      draggable={false}
                      className="object-contain object-center"
                      priority
                    />
                  </a>
                </NextLink>
              )}
            </div>

            <FacadeSearchBoxDesktop
              className="w-full max-lg:hidden"
              placeholder="Busca productos aquí.."
            />

            {logo && (
              <NextLink href={routes.index}>
                <a
                  aria-label="Ir a la home"
                  className={`relative h-[53px] w-full max-w-[196px] ${logoMobile ? 'max-lg:hidden' : 'me-auto ms-6 max-lg:h-11 max-lg:w-36 lg:ms-0'}`}
                >
                  <Image
                    src={logo.src}
                    alt={logo?.alt ?? 'Logo Header Desktop'}
                    fill
                    draggable={false}
                    sizes="200px"
                    className="object-contain object-center"
                    priority
                  />
                </a>
              </NextLink>
            )}

            <CheckoutProvider>
              <Toolbar
                showLoginHeader={showLoginHeader}
                className="lg:w-full lg:border-b lg:border-b-primary-200 lg:pb-4"
              />
            </CheckoutProvider>
          </div>

          <div ref={cintaRef}>
            <Picture
              alt={
                customClaim?.mobileImage?.altText || 'Cinta Bigcrafters Mobile'
              }
              sources={[
                {
                  src: customClaim?.mobileImage?.sourceUrl || CintaMobile.src,
                  height:
                    customClaim?.mobileImage?.mediaDetails?.height ||
                    CintaMobile.height,
                  width:
                    customClaim?.mobileImage?.mediaDetails?.width ||
                    CintaMobile.width,
                  quality: 100,
                },
                {
                  src: customClaim?.desktopImage?.sourceUrl || CintaDesktop.src,
                  height:
                    customClaim?.desktopImage?.mediaDetails?.height ||
                    CintaDesktop.height,
                  width:
                    customClaim?.desktopImage?.mediaDetails?.width ||
                    CintaDesktop.width,
                  media: 1024,
                  quality: 100,
                },
              ]}
              classNameImg="max-h-20 lg:max-h-none object-contain object-center lg:object-cover"
              sizes="100wv"
            />
          </div>
        </div>
        <DesktopMenu />
      </div>
      <ToastCenter
        {...(withOutSticky &&
          entry?.intersectionRatio === 0 && {
            style: {
              zIndex: -10,
              width: '100%',
              inset: 'auto',
              top: 0,
              position: 'fixed',
            },
          })}
      />
    </header>
  );
};
